import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import SearchAppBar from "./SearchAppBar";
import MapCluster from "./Map";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { Buffer } from "buffer";
// import { Link } from "react-router-dom";
import "./styles.css";

const App = () => {
  const [riskObjects, setRiskObjects] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_ADDRESS,
  });

  const loadData = (area) => {
    setLoading(true);
    const base64code = Buffer.from(JSON.stringify(area)).toString("base64");
    api
      .get(`/inventory/${base64code}`)
      .then((response) => {
        setRiskObjects(response.data.results);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("ERROR", error);
      });
  };

  useEffect(() => {
    loadData("Centro (JMD1)");
    // window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SearchAppBar loadData={loadData} loading={loading} />

      <Box sx={{ overflow: "auto", padding: 0, margin: 0, height: "90vh" }}>
        <MapCluster riskObjects={riskObjects} />
      </Box>

      <footer
        style={{ backgroundColor: "#006161", height: "2.8vh", paddingTop: 4 }}
      >
        <Typography sx={{ color: "white", ml: 2 }} variant="subtitle2">
          <a
            href="https://mytreerisk.com"
            rel="noreferrer"
            target="_blank"
            style={{ textDecoration: "none", color: "white" }}
          >
            {" "}
            MyTreeRisk
          </a>
          - Plataforma para la gestión de la infraestructura verde
        </Typography>
      </footer>
    </>
  );
};

export default App;
