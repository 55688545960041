import React, { useState } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Logo from "./Logo";

import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";

const SearchAppBar = ({ loadData, loading }) => {
  const [area, setArea] = useState("Centro (JMD1)");
  const handleChange = (event) => {
    loadData(event.target.value);
    setArea(event.target.value);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ background: "#006161" }}>
        <Toolbar>
          <Logo />
          <FormControl
            sx={{
              m: 1,
              maxWidth: 200,
              display: "flex",
              alignItems: "right",
              justifyContent: "center",
              fontSize: 12,
            }}
          >
            <InputLabel
              id="simple-select-autowidth-label"
              sx={{
                color: "white",
                borderRadius: "2px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ffffff",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ffffff",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline .Select .MuiSelectRoot":
                  {
                    borderColor: "#ffffff",
                  },
                // "&:hover": {
                //   backgroundColor: alpha("white", 0.25),
                // },
              }}
            >
              <Typography sx={{ color: "white" }}>Distrito</Typography>
            </InputLabel>
            {loading && (
              <CircularProgress
                sx={{
                  color: "white",
                  marginLeft: "40%",
                  position: "absolute",
                }}
              />
            )}
            <Select
              value={area}
              onChange={handleChange}
              autoWidth
              label="Elige tu distrito"
              sx={{
                fontSize: 12,
                color: "white",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },
              }}
            >
              <MenuItem value="">
                <em>Ninguno</em>
              </MenuItem>
              <MenuItem value={"Centro (JMD1)"}>Centro (JMD1)</MenuItem>
              <MenuItem value={"Este (JMD2)"}>Este (JMD2)</MenuItem>
              <MenuItem value={"Ciudad Jardín (JMD3)"}>
                Ciudad Jardín (JMD3)
              </MenuItem>
              <MenuItem value={"Bailén - Miraflores (JMD4)"}>
                Bailén - Miraflores (JMD4)
              </MenuItem>
              <MenuItem value={"Palma - Palmilla (JMD5)"}>
                Palma - Palmilla (JMD5)
              </MenuItem>
              <MenuItem value={"Cruz de Humilladero (JMD6)"}>
                Cruz de Humilladero (JMD6)
              </MenuItem>
              <MenuItem value={"Carretera de Cádiz (JMD7)"}>
                Carretera de Cádiz (JMD7)
              </MenuItem>
              <MenuItem value={"Churriana (JMD8)"}>Churriana (JMD8)</MenuItem>
              <MenuItem value={"Campanillas (JMD9)"}>
                Campanillas (JMD9)
              </MenuItem>
              <MenuItem value={"Puerto de la Torre (JMD10)"}>
                Puerto de la Torre (JMD10)
              </MenuItem>
              <MenuItem value={"Teatinos - Universidad (JMD11)"}>
                Teatinos - Universidad (JMD11)
              </MenuItem>
            </Select>
          </FormControl>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default SearchAppBar;
