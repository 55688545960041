import Box from "@mui/material/Box";
function Logo() {
  return (
    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
      <img
        src="assets/images/logos/Parquesyjardines-mytreerisk.png"
        alt="mytreerisk logo"
        style={{ maxWidth: 245 }}
      />
    </Box>
  );
}

export default Logo;
