import React, { useRef, memo } from "react";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import treeIconPng from "./treeIcons/tree-green-light.png";
import palmTreeIconPng from "./treeIcons/palm-tree-green-light.png";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import "./styles.css";

const tree = new Icon({
  iconUrl: treeIconPng,
  iconSize: [28, 28],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});
const palm = new Icon({
  iconUrl: palmTreeIconPng,
  iconSize: [30, 30],
  // iconAnchor: [12, 41],
  // popupAnchor: [1, -34],
});
const MapCluster = ({ riskObjects }) => {
  const pageLayout = useRef(null);
  //   const [active, setActive] = useState(false);

  const greenIcon = {
    Árbol: tree,
    Palmera: palm,
  };

  return (
    <MapContainer
      center={[36.7180676, -4.4398297]}
      zoom={13}
      preferCanvas
      // maxZoom={20}
      boxZoom
      scrollWheelZoom={true}
      zoomSnap={1}
      zoomAnimation={false}
      fadeAnimation={false}
      markerZoomAnimation={false}
      //   style={{ height: "90vh", width: "80wh" }}
      // trackResize
      style={{ height: "90vh" }}
      ref={pageLayout}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        maxZoom={22}
        maxNativeZoom={19}
      />
      <MarkerClusterGroup
        disableClusteringAtZoom={18}
        animate={false}
        chunkedLoading
        removeOutsideVisibleBounds
        spiderfyOnMaxZoom={false}
        // chunkProgress = {updateProgressBar}
      >
        {riskObjects &&
          riskObjects.map((tree) => (
            <Marker
              key={tree.id}
              position={[
                tree.position.latlng.split(",")[0],
                tree.position.latlng.split(",")[1],
              ]}
              icon={greenIcon[tree.object_type]}
            >
              <Popup>
                <Card style={{ border: "none", boxShadow: "none" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItem: "center",
                      justifyContent: "center",
                      height: 300,
                    }}
                  >
                    {tree.images[0] && (
                      <CardMedia
                        style={{
                          width: "auto",
                          maxHeight: 300,
                        }}
                        component="img"
                        image={`https://backend.mytreerisk.freemem.space/${tree.images[0]}`}
                      />
                    )}
                  </div>
                  {/* <CardMedia
                    style={{
                      height: 200,
                    }}
                    image={`https://backend.mytreerisk.freemem.space/${tree.images[0]}`}
                    title="green iguana"
                  /> */}
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {`${tree.object_type}: ${tree.model_object}`}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {tree.position.full_address}
                    </Typography>
                  </CardContent>
                </Card>
              </Popup>
            </Marker>
          ))}
      </MarkerClusterGroup>
    </MapContainer>
  );
};

export default memo(MapCluster);
